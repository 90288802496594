import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { CHANNELCOUNT } from 'tvm-config';
import { usePaginatedQuery } from '@src/lib/graphql/urql';
import { useTvGuideStore } from '@src/stores/tvguide';
import { GetChannelsDayShowtimesDocument, TvChannelDay } from '@lib/graphql/generated';
import { ColumnView } from './ColumnView';
import { TimelineView } from './TimelineView';

export type TvGuideHandle = {
  changeScrollPosition: (count: number) => void;
};

export interface TvGuideProps {
  columnView: boolean;
  activeDay: Date;
}

export const TvGuide = ({ columnView, activeDay }) => {
  const updateTvCurrentTime = useTvGuideStore((state) => state.updateTvCurrentTime);

  const [{ data: channelsData, fetching, hasMorePages, fetchNextPage, currentPage }] = usePaginatedQuery({
    query: GetChannelsDayShowtimesDocument,
    variables: {
      page: 1,
      perPage: CHANNELCOUNT,
      date: format(activeDay, 'yyyy-MM-dd'),
    },
    // requestPolicy: 'cache-first',
  });

  const channelEntriesTemp = useRef<TvChannelDay[]>();

  useEffect(() => {
    if (!fetching) channelEntriesTemp.current = channelsData?.flatMap((data) => data?.channelEntries?.data) || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  const channelEntries = fetching
    ? channelEntriesTemp.current
    : channelsData?.flatMap((data) => data?.channelEntries?.data);

  useEffect(() => {
    const interval = setInterval(() => updateTvCurrentTime(), 5000);
    return () => {
      clearInterval(interval);
    };
  }, [updateTvCurrentTime]);

  return (
    <div className="relative w-full">
      {columnView ? (
        <ColumnView
          channelEntries={channelEntries}
          activeDay={activeDay}
          fetching={fetching}
          onFetchMore={fetchNextPage}
          hasMorePages={hasMorePages}
          currentPage={currentPage}
        />
      ) : (
        <TimelineView
          channelEntries={channelEntries}
          activeDay={activeDay}
          fetching={fetching}
          onFetchMore={fetchNextPage}
          hasMorePages={hasMorePages}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};
