import React, { useEffect, useRef } from 'react';
import { addDays, isBefore, isSameDay, set, subMilliseconds } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import { TvChannelDay } from '@lib/graphql/generated';
import { ColumnProgramEntry } from './ColumnProgramEntry';
import { FeaturedProgramEntry } from './FeaturedProgramEntry';

type rangeTime = { hours: number; minutes: number };
export interface ColumnChannelProgramProps {
  activeDay: Date;
  initialLoad?: boolean;
  rangeFrom: rangeTime;
  rangeTo: rangeTime;
  active: boolean;
  channelEntry?: TvChannelDay;
  containsPrimeTime?: boolean;
  currentTime: Date;
}

export const ColumnChannelProgram = ({
  active,
  activeDay,
  channelEntry,
  rangeFrom,
  rangeTo,
  containsPrimeTime,
  currentTime,
}: ColumnChannelProgramProps) => {
  const lastActiveDay = useRef(activeDay);
  const dateOverflow = rangeTo.hours < rangeFrom.hours;

  const rangeFromDate = set(new Date(activeDay), {
    hours: rangeFrom.hours,
    minutes: rangeFrom.minutes,
    seconds: 0,
    milliseconds: 0,
  });
  const rangeToDate = set(new Date(addDays(activeDay, dateOverflow ? 1 : 0)), {
    hours: rangeTo.hours,
    minutes: rangeTo.minutes,
    seconds: 59,
    milliseconds: 999,
  });

  const channelShowtimes = channelEntry?.showtimes?.filter(
    (showtime) => isBefore(subMilliseconds(rangeFromDate, 1), showtime.start) && isBefore(showtime.start, rangeToDate),
  );

  useEffect(() => {
    if (!isSameDay(activeDay, lastActiveDay.current)) {
      lastActiveDay.current = activeDay;
    }
  }, [active, activeDay]);

  if (!active || !channelEntry) return;

  return (
    <div className={twMerge('relative h-full min-w-0 max-w-full space-y-1 transition-opacity duration-300')}>
      {channelShowtimes?.length > 0 &&
        channelShowtimes.map((showtime, idx) =>
          containsPrimeTime && idx === 0 ? (
            <FeaturedProgramEntry
              showtime={showtime}
              channel={channelEntry.channel}
              currentTime={currentTime}
              type="primetime"
              key={showtime.id}
            />
          ) : (
            <ColumnProgramEntry
              key={showtime.id}
              entry={showtime}
              channel={channelEntry?.channel}
              currentTime={currentTime}
            />
          ),
        )}
    </div>
  );
};
