import { MutableRefObject, useCallback, useLayoutEffect, useRef } from 'react';

export function useScrollSync(...refs: MutableRefObject<HTMLElement>[]) {
  const timeoutId = useRef(null);
  const lastRefs = useRef(refs);

  const handleScroll = useCallback(
    (scroll, smooth = false, origin = null) => {
      let changed = false;
      for (let i = 0; i < refs.length; i++) {
        if (
          refs[i].current?.scrollLeft !== scroll ||
          refs[i].current?.scrollWidth !== lastRefs.current[i]?.current?.scrollWidth
        ) {
          changed = true;
          break;
        }
      }

      if (!changed) return;

      const syncedRefs = refs.filter((ref) => ref.current !== origin);
      const targetScrollLeft = scroll;

      clearTimeout(timeoutId.current);

      syncedRefs.forEach((ref) => {
        removeEvent(ref);
      });

      syncedRefs.forEach((ref) => {
        if (smooth) {
          ref.current.scrollTo({
            behavior: 'smooth',
            left: targetScrollLeft,
          });
        } else {
          ref.current.scrollLeft = targetScrollLeft;
        }
      });

      timeoutId.current = setTimeout(
        () => {
          syncedRefs.forEach((ref) => {
            addEvent(ref);
          });
        },
        smooth ? 800 : 120,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refs],
  );

  const handleEventScroll = useCallback(
    (evt) => {
      handleScroll(evt.target.scrollLeft, false, evt.target);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refs],
  );

  const addEvent = useCallback(
    (ref) => {
      ref.current?.addEventListener('scroll', handleEventScroll, {
        passive: true,
      });
    },
    [handleEventScroll],
  );

  const removeEvent = useCallback(
    (ref) => {
      ref.current?.removeEventListener('scroll', handleEventScroll, {
        passive: true,
      });
    },
    [handleEventScroll],
  );

  useLayoutEffect(() => {
    refs.forEach(addEvent);
    return () => {
      clearTimeout(timeoutId.current);
      refs.forEach(removeEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs]);

  return handleScroll;
}
