import React, { useCallback, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { differenceInMinutes, isAfter, isSameDay, isToday, set, startOfDay } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import { TIMESCALE } from 'tvm-config';
import { Button } from '@components/atoms/Button/Button';
import { LoadingIndicator } from '@components/atoms/LoadingIndicator/LoadingIndicator';
import { IconCarretRight } from '@src/assets/icon-carret-right';
import { useTvGuideStore } from '@src/stores/tvguide';
import { useScrollSync } from '@src/utils/useScrollSync';
import { TvChannelDay } from '@lib/graphql/generated';
import { ChannelBase, TimeBar, TimelineChannelProgram } from './';

const TimeIndicator = dynamic(() => import('./TimeIndicator').then((mod) => mod['TimeIndicator']), { ssr: false });

export interface TimelineViewProps {
  channelEntries: TvChannelDay[];
  activeDay: Date;
  fetching?: boolean;
  hasMorePages?: boolean;
  currentPage?: number;
  onFetchMore?: () => void;
}

export const TimelineView = ({ channelEntries, activeDay, fetching, currentPage, hasMorePages, onFetchMore }) => {
  const now = useTvGuideStore((state) => state.tvCurrentTime);
  const [initiallyScrolled, setInitiallyScrolled] = useState(false);
  const scrollMargin = () => (window?.innerWidth > 767 ? 300 : 50);
  const documentRef = useRef<Document>(null);

  const [draggingBlocked, setDraggingBlocked] = useState(false);
  const [dragging, setDragging] = useState(false);
  const dragPositions = useRef({
    left: null,
    top: null,
    x: null,
    y: null,
  });

  const primeTime = new Date(activeDay.getFullYear(), activeDay.getMonth(), activeDay.getDate(), 20, 15, 0, 0);

  const timelineScrollElement = useRef(null);
  const showtimeScrollElement = useRef(null);
  const syncedScroll = useScrollSync(timelineScrollElement, showtimeScrollElement);

  const firstFetchDate = startOfDay(activeDay);

  function mouseMoveListener(evt) {
    const dx = evt.clientX - dragPositions.current.x;
    const dy = evt.clientY - dragPositions.current.y;
    if (!dragging && (Math.abs(dx) > 5 || Math.abs(dy) > 5)) {
      setDragging(true);
    }
    if (showtimeScrollElement.current) {
      showtimeScrollElement.current.scrollLeft = dragPositions.current.left - dx;
      document.documentElement.scrollTop = dragPositions.current.top - dy;
    }
  }

  function removeEventListeners() {
    documentRef.current?.removeEventListener('mousemove', mouseMoveListener);
    documentRef.current?.removeEventListener('mouseup', mouseUpListener);
  }

  function mouseUpListener() {
    if (typeof window !== 'undefined') {
      document.documentElement.style.scrollBehavior = 'smooth';
    }

    setTimeout(() => {
      removeEventListeners();
      setDragging(false);
    }, 1);
  }

  function handleMouseDown(evt) {
    if (draggingBlocked) return;
    window.requestAnimationFrame(() => {
      documentRef.current.addEventListener('mousemove', mouseMoveListener);
      documentRef.current.addEventListener('mouseup', mouseUpListener);
    });
    if (showtimeScrollElement) {
      document.documentElement.style.scrollBehavior = 'auto';
    }
    dragPositions.current = {
      left: showtimeScrollElement.current.scrollLeft,
      top: document.documentElement.scrollTop,
      x: evt.clientX,
      y: evt.clientY,
    };
  }

  function scrollTimelineWindow(direction) {
    syncedScroll(showtimeScrollElement.current.scrollLeft + direction * window.innerWidth * 0.8, true);
  }

  function scrollToPrimeTime() {
    if (showtimeScrollElement.current) {
      setDraggingBlocked(true);
      setDragging(false);
      removeEventListeners();
      syncedScroll(differenceInMinutes(primeTime, firstFetchDate) * TIMESCALE - scrollMargin(), true);
      setTimeout(() => {
        setDraggingBlocked(false);
        setInitiallyScrolled(true);
      }, 1000);
    }
  }

  const scrollToCurrent = useCallback(() => {
    if (showtimeScrollElement.current) {
      syncedScroll(differenceInMinutes(now, firstFetchDate) * TIMESCALE - scrollMargin(), initiallyScrolled);

      setTimeout(() => {
        setInitiallyScrolled(true);
      }, 1000);
    }
  }, [firstFetchDate, initiallyScrolled, now, syncedScroll]);

  useTvGuideStore.subscribe(
    (state) => state.openEntryData,
    () => {
      mouseUpListener();
    },
  );

  useEffect(() => {
    documentRef.current = document;
    setTimeout(() => {
      isToday(activeDay) ? scrollToCurrent() : scrollToPrimeTime();
    }, 1);

    return () => {
      removeEventListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={twMerge(
          'sticky top-28 z-30 w-full lg:top-20',
          draggingBlocked && '!pointer-events-none *:!pointer-events-none',
        )}
      >
        <div className="absolute inset-x-0 top-0 flex h-10 justify-between bg-black text-white">
          <button
            className="z-10 grid h-10 w-16 items-center bg-gradient-to-r from-black/100 via-black/100 via-70% to-black/0 pl-2 pr-4"
            onClick={() => {
              setTimeout(() => {
                scrollTimelineWindow(-1);
              });
            }}
          >
            <IconCarretRight classProps={{ root: 'rotate-180' }} />
          </button>
          <button
            className="z-10 grid h-10 w-16 items-center justify-end bg-gradient-to-l from-black/100 via-black/100 via-70% to-black/0 pl-4 pr-2"
            onClick={() => {
              setTimeout(() => {
                scrollTimelineWindow(1);
              });
            }}
          >
            <IconCarretRight />
          </button>
        </div>
        <div
          className="relative -mb-3 overflow-x-auto overflow-y-hidden pb-3 will-change-scroll scrollbar-none"
          ref={timelineScrollElement}
        >
          <TimeIndicator
            minutesSinceStart={Math.floor((primeTime.getTime() - firstFetchDate.getTime()) / 1000 / 60)}
            onAction={() => setTimeout(scrollToPrimeTime)}
            text="20:15"
            classProps={{
              root: 'ml-24 md:ml-52',
              leftBorder:
                isSameDay(activeDay, now) && isAfter(set(new Date(), { hours: 20, minutes: 15, seconds: 0 }), now)
                  ? 'left-[132px]'
                  : 'left-16',
              rightBorder: isAfter(set(new Date(), { hours: 20, minutes: 15, seconds: 0 }), now)
                ? 'right-16'
                : 'right-[132px]',
              chip: draggingBlocked && 'pointer-events-none',
            }}
          />
          {isSameDay(activeDay, now) && (
            <TimeIndicator
              minutesSinceStart={Math.floor((now.getTime() - firstFetchDate.getTime()) / 1000 / 60)}
              onAction={() => setTimeout(scrollToCurrent)}
              text="Jetzt"
              classProps={{
                root: 'ml-24 md:ml-52',
                rightBorder: isAfter(set(new Date(), { hours: 20, minutes: 15, seconds: 0 }), now)
                  ? 'right-[132px]'
                  : 'right-16',
                leftBorder: isAfter(set(new Date(), { hours: 20, minutes: 15, seconds: 0 }), now)
                  ? 'left-16'
                  : 'left-[132px]',
              }}
            />
          )}
          <TimeBar firstFetchDate={firstFetchDate} />
        </div>
      </div>
      <div className="absolute left-0 z-20 space-y-1 bg-black pr-1 shadow-xl">
        {channelEntries?.map((entry) => (
          <ChannelBase
            key={entry?.channel.id}
            channel={entry?.channel}
            bookmarked={false}
            // onFavoriteAction={(id) => {
            //   toggleFavorite(id);
            // }}
          />
        ))}
      </div>
      <div
        className={twMerge(
          'relative left-0 ml-24 cursor-pointer select-none overflow-x-auto overflow-y-hidden transition duration-1000 will-change-scroll scrollbar-none md:ml-52',
          dragging && 'cursor-move *:pointer-events-none',
        )}
        ref={showtimeScrollElement}
        onMouseDown={handleMouseDown}
      >
        <div
          className="absolute inset-y-0 left-0"
          style={{
            width: `${30 * 60 * TIMESCALE}px`,
          }}
        >
          {fetching && currentPage === null && (
            <div className="sticky left-0 z-40 grid min-h-full w-48 items-center md:w-100">
              {channelEntries?.map((e, index) => (
                <LoadingIndicator visible classProps={{ root: 'h-[72px] md:h-28 min-h-0' }} key={index} />
              ))}
            </div>
          )}
        </div>
        <div
          className="relative space-y-1 overflow-x-clip"
          style={{
            width: `${30 * 60 * TIMESCALE}px`,
          }}
        >
          {channelEntries?.map((entry) => <TimelineChannelProgram key={entry?.channel.id} channelEntry={entry} />)}
          <div className="pointer-events-none absolute -top-2 bottom-0 w-36 bg-gradient-to-r from-black to-black/0" />
          <div className="pointer-events-none absolute -top-2 bottom-0 right-0 w-36 bg-gradient-to-l from-black to-black/0" />
        </div>
        {isSameDay(activeDay, now) && (
          <TimeIndicator
            minutesSinceStart={Math.floor((now.getTime() - firstFetchDate.getTime()) / 1000 / 60)}
            onAction={scrollToCurrent}
            onlyLine
          />
        )}
      </div>
      {hasMorePages && (
        <div className="text-center">
          <Button
            title="Mehr laden"
            hasAction
            onAction={() => setTimeout(onFetchMore)}
            buttonStyle="secondary"
            isLoading={fetching}
            classProps={{ button: 'mt-6' }}
          />
        </div>
      )}
    </>
  );
};
