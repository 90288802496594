import React from 'react';
import { addMinutes, format } from 'date-fns';
import { TIMESCALE } from 'tvm-config';

export interface TimeBarProps {
  firstFetchDate: Date;
}

export const TimeBar = ({ firstFetchDate }: TimeBarProps) => {
  return (
    <div className="relative ml-24 h-10 md:ml-52">
      {[...Array(60)].map((_, index) => (
        <div
          key={index}
          className="absolute bottom-2.5 border-l-2 border-gray-650/65 pl-2 text-sm font-bold tracking-2px text-white"
          style={{ left: `${index * TIMESCALE * 30}px` }}
        >
          {format(addMinutes(new Date(firstFetchDate), 30 * index), 'HH:mm')}
        </div>
      ))}
    </div>
  );
};
