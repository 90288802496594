import React from 'react';
import { Image, Link, PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { getChannelLogo } from '@src/utils/sortedChannels';
import { TvChannel } from '@lib/graphql/generated';

// import { IconStar } from '@assets/icon-star';

export interface ChannelBaseProps extends PropsWithClassProps {
  channel: TvChannel;
  bookmarked?: boolean;
  columnView?: boolean;
}

export const ChannelBase = ({ bookmarked, columnView, channel, classProps }: ChannelBaseProps) => {
  const rootStyle = twMerge(
    'relative group md:w-52 h-[72px] md:h-28 grid grid-rows-[1fr,auto] gap-0 place-items-center rounded py-2 border-gray-500 cursor-pointer select-none content-auto channel-bg',
    classProps?.root,
    bookmarked && 'border-primary',
    columnView ? 'border-t-4 w-48' : 'border-l-4 w-24',
  );

  /**
   * * FAVORITE LABEL STYLE
   * Uncomment when login was implemented and favorite action is available
   */

  // const labelStyle = twMerge(
  //   'absolute tracking-2px transition duration-100 top-0 right-0 hidden md:block',
  //   columnView ? 'px-3 py-2' : 'p-2',
  //   bookmarked ? 'text-primary' : 'text-gray-500 opacity-50 hover:opacity-100 hover:text-primary',
  // );

  const logoPath = getChannelLogo(channel?.id);

  return (
    <div className={rootStyle}>
      <div className={twMerge('relative block h-8 w-14 md:h-10 md:w-28', columnView && 'w-16')}>
        {logoPath ? (
          <Image src={logoPath} alt={channel?.name} fill sizes="200px" copyright="" />
        ) : (
          channel?.logo && <Image src={channel.logo.file.path} alt={channel?.name} fill sizes="200px" copyright="" />
        )}
      </div>
      <Link classProps={{ root: 'absolute inset-0' }} href={'/sender/' + channel?.slug} />

      {/* FAVORITE LABEL */}

      {/* <div
        className={labelStyle}
        // onClick={(evt) => {
        //   evt.stopPropagation();
        //   onFavoriteAction(channel?.id);
        // }}
        title="Favoriten in Wunschreihenfolge auswählen und nach erneutem Laden sortiert vorfinden."
      >
        <IconStar classProps={{ root: 'w-4 h-4 stroke-2' }} filled={bookmarked} />
      </div> */}

      <div className="label-base line-clamp-1 px-1.5 transition-all duration-200 group-hover:opacity-100 md:h-0 md:opacity-0 md:group-hover:h-4">
        {channel.name}
      </div>
    </div>
  );
};
