import React from 'react';
import { format } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import { SHOW_SHOWTIME_PLAY_BUTTON } from 'tvm-config';
import { createDate, isCurrent, isCurrentOrPrevious } from '@utils/dateHelpers';
import { useTvGuideStore } from '@src/stores/tvguide';
import { TvChannel, TvChannelShowtime } from '@lib/graphql/generated';
import { IconPlayTvguide } from '@assets/icon-play-tvguide';

export interface ColumnProgramEntryProps {
  entry: TvChannelShowtime;
  currentTime: Date;
  channel: TvChannel;
}

export const ColumnProgramEntry = ({ entry, channel, currentTime }: ColumnProgramEntryProps) => {
  const setOpenEntryData = useTvGuideStore((state) => state.setOpenEntryData);
  const dragging = useTvGuideStore((state) => state.dragging);
  const isCurrentEntry = isCurrent(entry.start, entry.stop, currentTime);
  const baseStyle = twMerge(
    'pointer-events-auto grid justify-start text-left h-24 md:h-32 w-full min-w-0 cursor-pointer grid-rows-[1fr,auto] rounded-lg border border-gray-600 p-4 hover:border-primary',
    isCurrentEntry ? 'bg-gray-600' : 'bg-gray-700',
  );

  return (
    <button
      onClick={() => {
        if (dragging) return;
        setOpenEntryData(entry, channel);
      }}
      className={baseStyle}
    >
      <div className={twMerge('text-sm font-bold tracking-2px', isCurrentEntry ? 'text-primary' : 'text-gray-400')}>
        {SHOW_SHOWTIME_PLAY_BUTTON && isCurrentOrPrevious(entry.start, new Date().getTime()) && (
          <IconPlayTvguide classProps={{ root: 'inline-block mr-1.5' }} />
        )}
        {format(createDate(entry.start), 'HH:mm')}&nbsp;-&nbsp;
        {format(createDate(entry.stop), 'HH:mm')}
      </div>
      <div className="hyphenate line-clamp-2 text-base leading-body">{entry.title}</div>
    </button>
  );
};
