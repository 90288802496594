import React from 'react';
import { useTvGuideStore } from '@src/stores/tvguide';
import { TvChannelDay } from '@lib/graphql/generated';
import { TimelineProgramEntry } from './TimelineProgramEntry';

export interface TimelineChannelProgramProps {
  channelEntry: TvChannelDay;
}

export const TimelineChannelProgram = ({ channelEntry }: TimelineChannelProgramProps) => {
  const currentTime = useTvGuideStore((state) => state.tvCurrentTime);
  const showtimes = channelEntry?.showtimes;

  return (
    <div className="relative h-[72px] md:h-28">
      {showtimes?.length > 0 &&
        showtimes.map((showtime) => (
          <TimelineProgramEntry
            channel={channelEntry?.channel}
            entry={showtime}
            currentTime={currentTime}
            key={showtime.id}
          />
        ))}
    </div>
  );
};
