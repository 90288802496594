import { toZonedTime } from 'date-fns-tz';
import { CHANNELCOUNT } from 'tvm-config';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { TvChannel, TvChannelShowtime } from '@lib/graphql/generated';

type GuideViewType = 'timeline' | 'column';

type TvGuideStore = {
  openEntryData: { entry: TvChannelShowtime; channel: TvChannel };
  activeGuideDay: Date;
  tvCurrentTime: Date;
  guideScroll: number;
  dragging: boolean;
  activeView: GuideViewType;
  setActiveView: (activeView: GuideViewType) => void;
  toggleActiveView: () => void;
  setDragging: (dragging: boolean) => void;
  setOpenEntryData: (entry?: TvChannelShowtime, channel?: TvChannel) => void;
  setActiveGuideDay: (date: Date) => void;
  updateTvCurrentTime: () => void;
  setGuideScroll: (directionNumber: number) => void;
};

export const useTvGuideStore = create<TvGuideStore>()(
  subscribeWithSelector((set, get) => ({
    openEntryData: null,
    activeGuideDay: null,
    tvCurrentTime: toZonedTime(new Date(), 'Europe/Vienna'),
    dragging: false,
    guideScroll: 0,
    activeView: null,
    setActiveView: (activeView: GuideViewType) => set({ activeView }),
    toggleActiveView: () => set({ activeView: get().activeView === 'column' ? 'timeline' : 'column' }),
    setDragging: (dragging: boolean) => set({ dragging }),
    setOpenEntryData: (entry: TvChannelShowtime, channel: TvChannel) => set({ openEntryData: { entry, channel } }),
    setActiveGuideDay: (day: Date) => {
      set(() => ({ activeGuideDay: day, channelsVisible: CHANNELCOUNT }));
    },
    updateTvCurrentTime: () =>
      set({
        tvCurrentTime: toZonedTime(new Date(), 'Europe/Vienna'),
      }),
    setGuideScroll: (directionNumber: number) => set({ guideScroll: directionNumber }),
  })),
);
