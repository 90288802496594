import React from 'react';
import { PropsWithClassProps, composeStyles } from '@vgn-medien-holding/vgn-fe-components';
import { TIMESCALE } from 'tvm-config';

interface TimeIndicatorProps extends PropsWithClassProps<'leftBorder' | 'rightBorder' | 'chip'> {
  minutesSinceStart: number;
  onAction: () => void;
  text?: string;
  onlyLine?: boolean;
  showCaret?: boolean;
}

export const TimeIndicator = ({ text, onlyLine, minutesSinceStart, onAction, classProps }: TimeIndicatorProps) => {
  const defaultStyle = {
    root: 'pointer-events-none absolute bottom-0 left-0 top-1 z-10 grid justify-end transition-all duration-300',
    rightBorder: 'sticky right-10 w-px transition-all duration-300',
    leftBorder: 'sticky left-0 w-px transition-all duration-300',
    chip: 'pointer-events-auto z-50 mt-0.5 -ml-8 grid h-7 w-16 cursor-pointer items-center rounded-lg bg-primary text-center text-sm font-bold uppercase tracking-2px',
  };

  const styles = composeStyles(defaultStyle, classProps);

  return (
    <div className={styles.root} style={{ width: `${minutesSinceStart * TIMESCALE}px` }}>
      {onlyLine ? (
        <div className="absolute inset-y-0 right-0 w-px bg-primary">
          <svg className="-ml-1.5 -mt-2 size-3" viewBox="0 0 2 2">
            <path className="fill-primary" d="M0 0 L 2 0 L 1 2 Z" />
          </svg>
        </div>
      ) : (
        <div className={styles.rightBorder}>
          <div className="relative" style={{ width: `${(30 * 60 - minutesSinceStart) * TIMESCALE}px` }}>
            <div className={styles.leftBorder}>
              <div
                className={styles.chip}
                onClick={(evt) => {
                  evt.stopPropagation();
                  onAction();
                }}
                id="nowIndicatorElement"
              >
                {text}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
