import React, { PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';

const Header = dynamic(() => import('@components/organisms/Header/Header').then((module) => module.Header));
const Footer = dynamic(() => import('@components/organisms/Footer/Footer').then((module) => module.Footer));

interface DefaultFullWidthProps extends PropsWithChildren {
  hideMobileNav?: boolean;
}

export const DefaultFullWidth = ({ hideMobileNav, children }: DefaultFullWidthProps) => {
  return (
    <>
      <div className="dark relative h-screen w-full bg-black text-white">
        <Header classProps={{ root: 'relative' }} filled={false} hideMobileNav={hideMobileNav} />

        {/* INFO: -mt-px pt-px solves the issue with margin-top - please do not remove. :) */}
        <main className="-mt-px pt-px">
          <div className="w-full py-8 md:pt-16 lg:mt-24">{children}</div>
        </main>
        <Footer />
      </div>
    </>
  );
};
