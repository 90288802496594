import {
  FloatingButtonBar as FloatingButtonBarDefault,
  FloatingButtonBarProps as FloatingButtonBarPropsDefault,
  composeStyles,
} from '@vgn-medien-holding/vgn-fe-components';

export const FloatingButtonBar = ({ buttons, classProps }: FloatingButtonBarPropsDefault) => {
  const baseStyle = {
    root: 'group bottom-4 right-4 rounded-3xl border border-gray-650/25 bg-gray-820/80 backdrop-blur-sm lg:right-12 xl:right-16',
    button:
      'w-12 h-12 grid place-items-center rounded-full transition-colors duration-200 border border-transparent hover:border-primary hover:text-primary',
  };

  const styles = composeStyles(baseStyle, classProps);

  return <FloatingButtonBarDefault buttons={buttons} classProps={styles} />;
};
