import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';

export const IconViewColumns = ({ classProps }: PropsWithClassProps) => {
  return (
    <svg className={classProps?.root} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.925 10.125H3.075C1.95 10.125 1.5 10.605 1.5 11.7975V14.8275C1.5 16.02 1.95 16.5 3.075 16.5H14.925C16.05 16.5 16.5 16.02 16.5 14.8275V11.7975C16.5 10.605 16.05 10.125 14.925 10.125Z"
        fill="currentColor"
      />
      <path
        d="M14.925 1.5H3.075C1.95 1.5 1.5 1.98 1.5 3.1725V6.2025C1.5 7.395 1.95 7.875 3.075 7.875H14.925C16.05 7.875 16.5 7.395 16.5 6.2025V3.1725C16.5 1.98 16.05 1.5 14.925 1.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
