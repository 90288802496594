import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { AditionTagPlacement, AditionTagWithFallback } from '@vgn-medien-holding/vgn-fe-components';
import { getCookie } from 'cookies-next';
import { format, isBefore, set, sub } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { twMerge } from 'tailwind-merge';
import { CHANNELCOUNT } from 'tvm-config';
import { useShallow } from 'zustand/react/shallow';
import { AditionInitialization } from '@components/atoms/Adition/AditionInitialization';
import { Meta } from '@components/atoms/MetaTags/Meta';
import { Oewa } from '@components/atoms/Oewa/Oewa';
import { Title } from '@components/atoms/Title';
import { FloatingButtonBar } from '@components/molecules/FloatingButtonBar/FloatingButtonBar';
import { TvGuide } from '@components/organisms/TvGuide/TvGuide';
import { DefaultFullWidth } from '@components/templates/layouts/DefaultFullWidth';
import { isValidDateString } from '@utils/dateHelpers';
import { IconStar } from '@src/assets/icon-star';
import { reloadEveryPlacedAdslot } from '@src/lib/adition/adFarm';
import {
  GetBySlugDocument,
  GetChannelShowtimeByEventIdDocument,
  GetChannelsDayShowtimesDocument,
  TvChannelShowtime,
} from '@src/lib/graphql/generated';
import { useTvGuideStore } from '@src/stores/tvguide';
import { adPositions } from '@lib/adition/adPositions';
import { clearCache, ssrCache, ssrQuery } from '@lib/graphql/urql';
import { useTagManager } from '@lib/hooks/useTagManager';
import { useWebVitals } from '@lib/hooks/useWebVitals';
import { IconNext } from '@assets/icon-next';
import { IconViewBlocks } from '@assets/icon-view-blocks';
import { IconViewColumns } from '@assets/icon-view-columns';

const DatePicker = dynamic(() =>
  import('@components/organisms/DatePicker/DatePicker').then((mod) => mod['DatePicker']),
);
const ShowtimeDetails = dynamic(() =>
  import('@components/molecules/ShowtimeDetails/ShowtimeDetails').then((mod) => mod['ShowtimeDetails']),
);

function getValidatedDate(dateString) {
  if (!isValidDateString(dateString, 'yyyyMMdd')) {
    const now = new Date();
    const activeDay = isBefore(now, set(new Date(), { hours: 6, minutes: 0, seconds: 0, milliseconds: 0 }))
      ? sub(now, { days: 1 })
      : now;
    return activeDay;
  }

  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);

  return new Date(`${year}-${month}-${day}`);
}

function formatSlugDate(inputDate) {
  const date = inputDate.getDate().toString().padStart(2, '0');
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  const year = inputDate.getFullYear();
  return `${year}${month}${date}`;
}

export const TvProgram = ({ data }) => {
  const {
    openEntryData,
    setOpenEntryData,
    setActiveGuideDay,
    activeGuideDay,
    setActiveView,
    activeView,
    toggleActiveView,
  } = useTvGuideStore(
    useShallow((state) => ({
      openEntryData: state.openEntryData,
      setOpenEntryData: state.setOpenEntryData,
      setActiveGuideDay: state.setActiveGuideDay,
      activeGuideDay: state.activeGuideDay,
      activeView: state.activeView,
      setActiveView: state.setActiveView,
      toggleActiveView: state.toggleActiveView,
    })),
  );
  const initialRender = useRef(true);

  let activeDay: Date = activeGuideDay;
  if (activeGuideDay === null && initialRender.current) {
    setActiveGuideDay(getValidatedDate(data.activeDay));
    activeDay = getValidatedDate(data.activeDay);
  }

  let openEntryShowtime = openEntryData?.entry;
  let openEntryChannel = openEntryData?.channel;
  if (!openEntryData?.entry && initialRender.current) {
    initialRender.current = false;
    setOpenEntryData(data.openEntryShowtime, data.openEntryChannel);
    openEntryShowtime = data.openEntryShowtime;
    openEntryChannel = data.openEntryChannel;
  }

  let activeGuideView = activeView;
  if (!activeGuideView) {
    activeGuideView = !data.isTimeline ? 'column' : 'timeline';
    setActiveView(!data.isTimeline ? 'column' : 'timeline');
  }

  const { metadata } = data;
  const [customMetadata, setCustomMetadata] = useState({});

  const router = useRouter();

  const updateCustomMetaData = useCallback(
    (entry = null, channel = null) => {
      const metaEntry = entry || openEntryShowtime;
      const metaChannel = channel || openEntryChannel;
      const meta = {};

      if (metaEntry || activeGuideView !== 'column' || activeDay) {
        meta['canonical_link'] = '/programm';
      }

      const formattedDate = format(activeDay, 'dd.MM.yyyy', { locale: de });
      const formattedDateWeekday = format(activeDay, 'iiii', { locale: de });

      if (metaEntry) {
        meta['meta_title'] = `${metaEntry.title}${
          metaChannel?.name ? ' | ' + metaChannel.name : ''
        } | ${formattedDate} | TV-Programm | TV-MEDIA`;
        if (metaEntry.summary) {
          meta['meta_description'] =
            metaEntry.summary?.length > 157 ? metaEntry.summary?.slice(0, 155) + '..' : metaEntry.summary;
        }
      } else {
        meta['meta_title'] = `TV-Programm für ${formattedDateWeekday}, den ${formattedDate} | TV-MEDIA`;
        meta['meta_description'] =
          `TV-MEDIA, einfach fernsehen: Aktuelles Fernsehprogramm für ${formattedDateWeekday}, den ${formattedDate}. TV-Programm aller TV-Sender heute online: Sendungen, Filme und Serien.`;
      }

      setCustomMetadata(meta);
    },
    [activeDay, activeGuideView, openEntryChannel, openEntryShowtime],
  );

  function backToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const updateSlug = useCallback(
    async (entry = null, channel = null) => {
      updateCustomMetaData(entry, channel);
      const today = formatSlugDate(new Date());
      const activeDaySlug = formatSlugDate(new Date(activeDay));
      const slugify = (await import('react-slugify')).default;
      const entrySlugExtra = entry ? slugify(`${channel?.slug}-${entry.title || data?.openEntry?.title}`) : '';

      const slug = [
        ...(activeGuideView === 'column' ? [] : ['timeline']),
        ...(activeDaySlug === today ? [] : [activeDaySlug]),
        ...(entry?.event_id ? [`${entrySlugExtra}-${entry.event_id}`] : []),
      ];

      router.replace(
        {
          pathname: '/programm/[[...slug]]',
          query: {
            slug,
          },
        },
        undefined,
        { shallow: true },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeDay, activeGuideView],
  );

  useEffect(() => {
    reloadEveryPlacedAdslot();
    updateSlug(openEntryShowtime, openEntryChannel);
  }, [updateSlug, openEntryShowtime, openEntryChannel, activeDay, data.openEntry]);

  useTagManager({
    seitentyp: 'Programm',
    event: 'metadata',
    loginStatus: 'nicht eingeloggt',
    oewaSeitenkontingent: 'Service',
    oewaSubkontingent: 'ProgrammeUndLokalfuehrer',
    oewaProfilingkontingent: 'TVprogramme',
    cookieAlert: getCookie('OptanonConsent'),
  });

  useWebVitals();

  const floatingButtons = useMemo(
    () => [
      {
        icon: <IconNext classProps={{ root: 'size-5 -rotate-90' }} />,
        onClick: () => {
          backToTop();
        },
      },
    ],
    [],
  );

  return (
    <DefaultFullWidth hideMobileNav={true}>
      <article>
        <Oewa pageKey={'Service'} categoryKey={'ProgrammeUndLokalfuehrer'} profileKey={'TVprogramme'} />
        <AditionInitialization tags={adPositions} />
        <Head>{Meta({ ...metadata, ...customMetadata })}</Head>
        <div className="grid w-full place-items-center md:hidden">
          <AditionTagPlacement tag={adPositions[24]} classProps={{ root: 'mb-4 h-[300px]' }} />
        </div>
        <Title level={1} classProps={{ heading: 'text-4xl px-4 sm:px-8 md:px-16' }}>
          TV-Programm
        </Title>
        <section className="dark mt-14 grid items-center gap-x-4 gap-y-6 px-4 sm:pl-8 md:grid-cols-[1fr,auto] md:justify-between md:pl-16 lg:grid-cols-[1fr,auto,300px]">
          <DatePicker reloadEveryPlacedAdslot={reloadEveryPlacedAdslot} />

          <div className="flex items-center justify-center gap-x-4 md:justify-end">
            {false && (
              <button
                className="label-base flex h-12 w-full place-items-center justify-center gap-2 rounded-full border border-gray-650/25 bg-gray-820/70 p-4 transition-colors duration-200 hover:border-primary hover:text-primary"
                onClick={() => {}}
              >
                <IconStar classProps={{ root: 'w-4 h-4' }} filled />
                Favoriten
              </button>
            )}
            <button
              className="label-base flex h-12 w-full place-items-center justify-center gap-2 rounded-full border border-gray-650/25 bg-gray-820/70 p-4 transition-colors duration-200 hover:border-primary hover:text-primary"
              onClick={() => {
                setTimeout(() => {
                  toggleActiveView();
                  reloadEveryPlacedAdslot();
                });
              }}
            >
              {activeGuideView === 'column' ? (
                <IconViewColumns classProps={{ root: 'w-4 h-4' }} />
              ) : (
                <IconViewBlocks classProps={{ root: 'w-4 h-4' }} />
              )}
              Ansicht
            </button>
          </div>
        </section>
        <section className={twMerge('mt-6 flex px-4 sm:pl-8 md:pl-16')}>
          <div className="w-full min-w-0 xl:w-4/5 3xl:w-10/12">
            <TvGuide activeDay={new Date(activeDay)} columnView={activeGuideView === 'column'} />
          </div>
          <div className={'mt-8 hidden justify-start px-3 lg:flex'}>
            <div style={{ marginTop: activeGuideView === 'column' ? 0 : 8 }}>
              <AditionTagPlacement tag={adPositions[7]} classProps={{ root: ' sticky top-24 w-[300px]' }} />
            </div>
          </div>
        </section>
        <ShowtimeDetails
          key={openEntryShowtime?.event_id}
          entryEventId={openEntryShowtime?.event_id}
          channel={openEntryChannel}
          onFetchComplete={(entry, channel) => {
            updateSlug(entry, channel);
          }}
        />
        <div className="grid w-full place-items-center">
          <AditionTagWithFallback
            tag={adPositions[6]}
            fallback={adPositions[27]}
            breakpoint={'lg'}
            classProps={{ root: 'mt-8' }}
          />
        </div>
      </article>
      <FloatingButtonBar buttons={floatingButtons} />
    </DefaultFullWidth>
  );
};

export async function getServerSideProps({ params, res }) {
  res.setHeader('Cache-Control', 'public, s-maxage=86400, stale-while-revalidate=240');

  clearCache();

  let metadata;

  try {
    const { data } = await ssrQuery({
      query: GetBySlugDocument,
      variables: {
        portal: 'tvmedia',
        slug: '/tv-programm',
        coverImageWidth: 3840,
        coverImageHeight: 2160,
        coverImageFit: 'crop_focal',
      },
    });

    if (!data) return { notFound: true };

    metadata =
      data?.findBySlug?.__typename === 'Article' || data?.findBySlug?.__typename === 'Page'
        ? data?.findBySlug?.metadata || null
        : null;
  } catch (err) {
    metadata = null;
  }

  const isTimeline = params?.slug?.[0] === 'timeline';
  const openEntrySlug = params?.slug?.slice(-1)?.[0] || null;
  const openEntryId = openEntrySlug ? openEntrySlug.split('-')?.pop() : null;

  const activeDay = getValidatedDate(params?.slug?.[isTimeline ? 1 : 0]) ?? new Date();
  const formattedActiveDay = format(activeDay, 'yyyy-MM-dd');

  const channelsDay = await ssrQuery({
    query: GetChannelsDayShowtimesDocument,
    variables: {
      page: 1,
      perPage: CHANNELCOUNT,
      date: formattedActiveDay,
    },
  });

  let openEntryShowtime: TvChannelShowtime = null;

  let openEntryChannelDay =
    channelsDay?.data?.channelEntries?.data?.find((e) => {
      const entryShowtime = e?.showtimes?.find((s) => s?.event_id === openEntryId);
      if (entryShowtime) {
        openEntryShowtime = entryShowtime;
      }
      return !!entryShowtime;
    }) || null;

  // If the entry is not found in the current day, we need to fetch it from the API
  if (openEntryId && !openEntryShowtime) {
    const { data } = await ssrQuery({
      query: GetChannelShowtimeByEventIdDocument,
      variables: { id: openEntryId, includeChannel: true },
    });
    openEntryShowtime = data?.channelShowtimeByEventId;
    openEntryChannelDay =
      channelsDay?.data?.channelEntries?.data?.find((e) => e?.channel?.id === openEntryShowtime?.channel?.id) || null;
  }

  return {
    props: {
      data: {
        metadata,
        isTimeline,
        activeDay: format(activeDay, 'yyyyMMdd') || null,
        openEntryShowtime,
        openEntryChannel: openEntryChannelDay?.channel || null,
      },
      urqlState: ssrCache.extractData(),
    },
  };
}

export default TvProgram;
