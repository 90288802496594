import { useEffect } from 'react';
import { onINP } from 'web-vitals/attribution';

export const useWebVitals = () => {
  useEffect(() => {
    function sendToGoogleAnalytics({ name, value, id, attribution }) {
      const { eventEntry, eventTarget, eventType, loadState } = attribution;
      const { startTime, processingStart, processingEnd, duration, interactionId } = eventEntry;

      const eventParams = {
        metric_inp_value: value,
        metric_id: id,
        metric_inp_event_target: eventTarget,
        metric_inp_event_type: eventType,
        metric_inp_load_state: loadState,
        metric_inp_start_time: startTime,
        metric_inp_processing_start: processingStart,
        metric_inp_processing_end: processingEnd,
        metric_inp_duration: duration,
        metric_inp_interaction_id: interactionId,
      };

      // Send to Google Analytics
      gtag('event', name, eventParams);
    }
    onINP(sendToGoogleAnalytics);
  }, []);
};
